<div cwdjParallax [config]="{parallaxInitVal: -75, parallaxRatio: -1}" class="view">
  <div class="flex-center rgba-stylish-slight d-none d-md-inline">
    <div class="text-white text-center py-5 px-4 my-5">
      <div>
        <h2 class="card-title h1-responsive pt-3 mb-5 font-bold"><strong>About us</strong></h2>
        <a pageScroll mdbBtn color="white" outline="true" class="waves-light" mdbWavesEffect [fragment]="'stuart'">
          <mdb-icon fas icon="user" class="left"></mdb-icon>Stuart Hall
        </a>
        <a pageScroll mdbBtn color="white" outline="true" class="waves-light" mdbWavesEffect [fragment]="'jane'">
          <mdb-icon fas icon="user" class=left></mdb-icon>Jane Clark
        </a>
      </div>
    </div>
  </div>
</div>
<div class="d-sm-none">
  <img src="../../assets/images/room.png" class="img-fluid" alt="Photograph of the Northam Physiotherapy clinic room">
</div>
<div class="d-sm-none small-title">
  <h2>About us</h2>
</div>
<div id='stuart'>
  <div class="row">
    <div class="col-md-9">
      <h1><strong>Stuart Hall</strong></h1>
      <p>
        Stuart gained a 1st Class Physiotherapy degree from Keele University in 2003 and also won the best student award. He has worked for the NHS for 15 years at hospitals,
        GP practices, clinics and even a prison in Nottingham, Isle of Wight and Plymouth however has now settled in North Devon. He has attended many post graduate courses
        including accupuncture. In 2015 he completed his research into knee pain and the effects of stretching which gained him a Masters degree. In 2017 he was approached by
        Plymouth University to become a Physiotherapy Lecturer on the degree programme. He combines the lecturing role with Northam Physiotherapy clinic.
      </p>
      <p>
        In his spare time he is Chairman of the North Devon Hang Gliding and Paragliding Club. He can often be seen flying the cliffs at Abbotsham. He is holds an RYA Yachtmaster
        qualification and teaches yacht courses as well as a Private Pilot’s License and has a share in a vintage motor glider. He also enjoys weekends at home with Jane continuing
        to work their house and garden.   
      </p>
    </div>
    <div class="col">
      <img src='../../assets/images/stu.png'>
    </div>
  </div>
</div>
<div id='jane'>
  <div class="row">
    <div class="col-md-9">
      <h1><strong>Jane Clark</strong></h1> 
      <p>Jane qualified in 1990 from St Thomas’s Hospital in London. She worked in the NHS for 20 years and was the senior Physiotherapist in the Sports Medicine Department of
        Nottingham University Hospital. She has worked extensively with rugby players and being a keen skier has a special interest in skiing injuries. In 2010 Jane moved to Devon to 
        take on the post of Head of Rehabilitation at Royal Marines Base Chivenor. She is a yoga instructor and runs a yoga class to the public as well as to the Armed Forces, combining 
        this role with Northam Physiotherapy.
      </p>
      <p>
        In her spare time Jane enjoys gardening and enjoys nothing more than being in the garden listening to the Archers with the cats and Stuart working on the next phase of garden improvement.
      </p>
    </div>
    <div class="col">
      <img src="../../assets/images/jane.png">
    </div>
  </div>
</div>
  