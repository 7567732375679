<div cwdjParallax [config]="{parallaxInitVal: 0, parallaxRatio: -1}" class="view">
    <div class="flex-center rgba-stylish-slight d-none d-md-inline">
          <div class="text-white text-center py-5 px-4 my-5">
            <div>
              <h2 class="card-title h1-responsive pt-3 mb-5 font-bold"><strong>Conditions</strong></h2>
              <a pageScroll mdbBtn color="white" outline="true" class="waves-light" mdbWavesEffect [fragment]="'tendon'">
                <mdb-icon fas icon="medkit" class="left"></mdb-icon>Tendinopathy
              </a>
              <a pageScroll mdbBtn color="white" outline="true" class="waves-light" mdbWavesEffect [fragment]="'sports'">
                <mdb-icon fas icon="running" class=left></mdb-icon>Sports injury
              </a>
              <a pageScroll mdbBtn color="white" outline="true" class="waves-light" mdbWavesEffect [fragment]="'shoulder'">
                <mdb-icon fas icon="male" class="left"></mdb-icon>Shoulder pain
              </a>
              <a pageScroll mdbBtn color="white" outline="true" class="waves-light" mdbWavesEffect [fragment]="'chronicpain'">
                <mdb-icon fas icon="bolt" class="left"></mdb-icon>Chronic Pain
              </a>
              <a pageScroll mdbBtn color="white" outline="true" class="waves-light" mdbWavesEffect [fragment]="'back'">
                <mdb-icon fas icon="people-carry" class="left"></mdb-icon>Low back pain
              </a>
            </div>
          </div>
    </div>
</div>
<div class="d-sm-none">
    <img src="../../assets/images/conditions.png" class="img-fluid" alt="Image of a person pointing at a model of a knee joint">
</div>
<div class="d-sm-none small-title">
    <h2>Conditions</h2>
</div>
<div id='disclaimer'>
    <h2>Disclaimer</h2>
    <p>On this page you will find some information about common conditions which we can help you with. Whilst every care
         has been taken in preparing the information on this page; it is not a substitute for medical advice.</p>
</div>
<div>
    <div class="row d-flex">
        <div class="col-md-4 d-flex" id='tendon'>
            <mdb-card>
                <mdb-card-img src="../../assets/images/anne-nygard-_W94Eb1iNYc-unsplash.jpg" alt="Image of a model of a foot, showing the muscles and tendons"></mdb-card-img>
                <mdb-card-body>
                    <mdb-card-title>
                        <h4>Tendinopathy</h4>
                    </mdb-card-title>
                    <mdb-card-text>This is sometimes known as tendinitis or tendinosis.
                        A tendon is tough band of fibrous connective tissue that connects muscle to bone and is able to withstand strong tension. If you look at a picture of a muscle the tendon is the brilliant white part seen at either end.
                        Tendinopathy is a swelling of the tendon which can cause joint pain and stiffness. 
                        </mdb-card-text>
                    <button mdbBtn color="black" outline="true" class="waves-light" mdbWavesEffect (click)="btnTendinopathyClick();">
                        More <mdb-icon fas icon="ellipsis-h" class="right"></mdb-icon>
                    </button>
                </mdb-card-body>
            </mdb-card>
        </div>
        <div class="col-md-4 d-flex" id='sports'>
            <mdb-card>
                <mdb-card-img src="../../assets/images/austris-augusts-52p1K0d0euM-unsplash.jpg" alt="Image of a running track"></mdb-card-img>
                <mdb-card-body>
                    <mdb-card-title>
                        <h4>Sports injury</h4>
                    </mdb-card-title>
                    <mdb-card-text>Sports injuries are the kinds of injuries that occur either during or as a result of a spot of exercise. While it is possible to injure any part of the body playing sport the term is commonly used to refer to injuries to the musculoskeletal system.
                        We often get asked if we are sports Physiotherapists or do we deal with sports injuries. The answer to this is we are musculoskeletal Physiotherapists, able to manage every kind of condition affecting the muscular and skeletal system. Sports injuries are a small part of this, so yes, we do, but that is just a part of what we do. We both have many years of experience in managing and rehabilitating people back to sport.</mdb-card-text>
                    <button mdbBtn color="black" outline="true" class="waves-light" mdbWavesEffect (click)="btnSportsClick();">
                        More <mdb-icon fas icon="ellipsis-h" class="right"></mdb-icon>
                    </button>
                </mdb-card-body>
            </mdb-card>
        </div>
        <div class="col-md-4 d-flex" id='shoulder'>
            <mdb-card>
                <mdb-card-img src="../../assets/images/chronicpain.jpg" alt="Image of an woman holding her shoulder"></mdb-card-img>
                <mdb-card-body>
                    <mdb-card-title>
                        <h4>Shoulder pain</h4>
                    </mdb-card-title>
                    <mdb-card-text>
                        <p>This is one of the most common conditions that we see at Northam Physiotherapy. Shoulder problems can be very painful, particularly at night 
                            and finding a comfortable position can be difficult.
                        </p>
                    </mdb-card-text>
                    <button mdbBtn color="black" outline="true" class="waves-light" mdbWavesEffect (click)="btnShoulderClick();">
                        More <mdb-icon fas icon="ellipsis-h" class="right"></mdb-icon>
                    </button>
                </mdb-card-body>
            </mdb-card>
        </div>
        <div class="col-md-4 d-flex" id='chronicpain'>
            <mdb-card>
                <mdb-card-img src="../../assets/images/pexels-karolina-grabowska-4506217.jpg" alt="Image showing people working at computers"></mdb-card-img>
                <mdb-card-body>
                    <mdb-card-title>
                        <h4>Chronic pain</h4>
                    </mdb-card-title>
                    <mdb-card-text>Everyone experiences occasional aches and pains. In fact, sudden pain is an important reaction of the nervous system that helps alert you to possible injury. When an injury occurs, pain signals travel from the injured area up your spinal cord and to your brain.
                        Pain will usually become less severe as the injury heals and the “emergency” in the body is over. However, chronic pain is different from typical pain. With chronic pain, your body continues to send pain signals to your brain, even after an injury heals. This can last several weeks to years.</mdb-card-text>
                    <button mdbBtn color="black" outline="true" class="waves-light" mdbWavesEffect (click)="btnChronicClick();">
                        More <mdb-icon fas icon="ellipsis-h" class="right"></mdb-icon>
                    </button>
                </mdb-card-body>
            </mdb-card>
        </div>
        <div class="col-md-4 d-flex" id='back'>
            <mdb-card>
                <mdb-card-img src="../../assets/images/back.jpeg" alt="Image of a person holding their back"></mdb-card-img>
                <mdb-card-body>
                    <mdb-card-title>
                        <h4>Low back pain</h4>
                    </mdb-card-title>
                    <mdb-card-text>The lumbar spine supports the weight of the upper body and provides mobility for everyday activities like bending, lifting and twisting. The muscles in the low back and hips are responsible for walking and the nerves in the low back supply the power and sensation to the legs and feet.
                        A consequence of walking on 2 feet means that most people will experience low back pain at some time in their lives, but the good news is that it is rarely serious (although it can be very painful).</mdb-card-text>
                    <button mdbBtn color="black" outline="true" class="waves-light" mdbWavesEffect (click)="btnLowBackClick();">
                        More <mdb-icon fas icon="ellipsis-h" class="right"></mdb-icon>
                    </button>
                </mdb-card-body>
            </mdb-card>
        </div>
    </div>
</div>