<div cwdjParallax [config]="{parallaxInitVal: -125, parallaxRatio: -1}" class="view">
    <div class="flex-center rgba-stylish-slight d-none d-md-inline">
        <div class="text-white text-center py-5 px-4 my-5">
              <h2 class="card-title h1-responsive pt-3 mb-5 font-bold"><strong>Low back pain</strong></h2>
              <a pageScroll mdbBtn color="white" outline="true" class="waves-light" mdbWavesEffect [fragment]="'startpain'">
                <mdb-icon fas icon="question" class="left"></mdb-icon>How does pain start?
              </a>
              <a pageScroll mdbBtn color="white" outline="true" class="waves-light" mdbWavesEffect [fragment]="'sciatica'">
                <mdb-icon fas icon="bolt" class="left"></mdb-icon>Sciatica
              </a>
              <a pageScroll mdbBtn color="white" outline="true" class="waves-light" mdbWavesEffect [fragment]="'changes'">
                <mdb-icon fas icon="exclamation" class="left"></mdb-icon>Important changes
              </a>
              <button mdbBtn color="white" outline="true" class="waves-light" mdbWavesEffect (click)="btnBackClick();">
                <mdb-icon fas icon="chevron-left" class="left"></mdb-icon>Conditions
              </button>
        </div>
    </div>
</div>
<div class="d-sm-none">
    <img src="../../assets/images/back.jpeg" class="img-fluid" alt="Image of acupuncture needles in an arm">
</div>
<div class="d-sm-none small-title">
    <h2>Low back pain</h2>
</div>
<div id='intro'>
    <p>The lumbar spine supports the weight of the upper body and provides mobility for everyday activities like bending, lifting and twisting. The muscles in the low back and hips are responsible for walking and the nerves in the low back supply the power and sensation to the legs and feet.
        A consequence of walking on 2 feet means that most people will experience low back pain at some time in their lives, but the good news is that it is rarely serious (although it can be very painful).</p>
</div>
<div id='startpain'>
    <h2>How does pain start?</h2>
    <p>Simply, pain can start in of 4 ways:</p>
    <ol>
        <li>An injury – a fall or an accident could damage to bones, joints or soft tissues</li>
        <li>A sudden onset – for example picking up a heavy object and feeling the back ‘go’ can be sign that something has been overloaded. This 
        could be a disc or a muscular.</li>
        <li>A gradual onset (so no history of injury or anything sudden) suggests that there isn’t tissue damage, but that there is irritation. This
        may be due to things like repetitive activities or altered posture.</li>
        <li>Waking up in the morning and feeling pain is also common, and this may be due to an overly active previous day.  In people under 40 years 
        old, this stiffness and discomfort can be because the discs in the spine are strained.  The discs are the tough cushions between the bones
        of the spine.  They are 70% water, and dehydrate during the day but re-hydrate overnight (so we are taller in the morning than in the 
        evening). This can cause extra ‘pressure’ in the spine when the disc has had a strain.  For older people, the stiffness in the morning can 
        be age-related wear and tear of the joints in the spine.</li>
    </ol>
    <p>Most types of low back pain are mechanical (which means it is due to the muscles, ligaments and joints around the spine. The pain is usually 
    around the back, hips and top of the legs. It can be altered by moving in certain directions.</p>
</div>
<div id='sciatica'>
    <h2>Sciatica</h2>
    <div class='row'>
    <div class='col-md-8 flex' id='sciaticatext'>
        <p>Sciatic pain is a cause of the nerve in the back becoming inflamed or pinched which then causes pain to travel or be felt in the leg. There are
        many reasons for this, and the further down the leg pain is felt the more the nerve is pinched.</p>
        <p>Sciatic pain can be very debilitating, but the good news is that in most cases it does get better, and at 6 months even the worse symptoms can
            be better. That doesn’t mean that everyone will but being positive and engaging in physiotherapy gives the most potential for recovery.</p>        
    </div>
    <div class='col-md-3 flex' id='sciaticaimage'>
        <img src="../../assets/images/3D_still_showing_Sciatica_nerve.jpg">
    </div>
    </div>
</div>
<div id='changes'>
    <h2>Changes to look out for</h2>    
    <p>Although low back pain can be very painful, the pain does not mean it is serious. The very important thing is whether you have had a new, 
    unusual change in your bladder or bowel since the back pain started. If you are unable to empty your bladder or are incontinent of your bowels 
    you need to contact A&E. Pressure on the nerves which control bladder and bowel do not tend to do well and you may need to have urgent treatment,
    so do not delay!</p>
    <button id='bottombackbutton' mdbBtn color="black" outline="true" class="waves-light" mdbWavesEffect (click)="btnBackClick();">
        <mdb-icon fas icon="chevron-left" class="left"></mdb-icon>Conditions
    </button>
</div>

