<div class="view">
    <img src="../../assets/images/room.png" class="img-fluid"  alt="Image of the clinic room at Northam Physiotherapy">
    <div class="mask flex-center rgba-stylish-slight">
          <div class="text-white text-center py-5 px-4 my-5">
            <div>
              <h1 class="card-title h1-responsive pt-3 mb-5 font-bold"><strong>4 Oh 4</strong></h1>
              <h2>The page you are looking for doesn't exist!</h2>
              <h3>Whilst we look for it, click below to go back to the home page.</h3>
              <a mdbBtn color="white" outline="true" class="waves-light" mdbWavesEffect href="home">
                <mdb-icon fas icon="arrow-alt-circle-left" class="left"></mdb-icon>Go to Home
              </a>
            </div>
          </div>
    </div>
  </div> 
