<div cwdjParallax [config]="{parallaxInitVal: -125, parallaxRatio: -1}" class="view">
    <div class="flex-center rgba-stylish-slight d-none d-md-inline">
        <div class="text-white text-center py-5 px-4 my-5">
              <h2 class="card-title h1-responsive pt-3 mb-5 font-bold"><strong>Manual Therapy</strong></h2>
              <a pageScroll mdbBtn color="white" outline="true" class="waves-light" mdbWavesEffect [fragment]="'mobilisation'">
                <mdb-icon fas icon="running" class="left"></mdb-icon>Mobilisation, Manipulation and Massage
              </a>
              <a pageScroll mdbBtn color="white" outline="true" class="waves-light" mdbWavesEffect [fragment]="'other'">
                <mdb-icon fas icon="hand-paper" class="left"></mdb-icon>Other Manual Therapies
              </a>
              <a pageScroll mdbBtn color="white" outline="true" class="waves-light" mdbWavesEffect [fragment]="'conditions'">
                <mdb-icon fas icon="capsules" class="left"></mdb-icon>Conditions suitable for manual therapy
              </a>
              <button mdbBtn color="white" outline="true" class="waves-light" mdbWavesEffect (click)="btnBackClick();">
                <mdb-icon fas icon="chevron-left" class="left"></mdb-icon>Treatments
              </button>
        </div>
    </div>
</div>
<div class="d-sm-none">
    <img src="../../assets/images/toa-heftiba-hBLf2nvp-Yc-unsplash.jpg" class="img-fluid" alt="Image of a person having massage therapy">
</div>
<div class="d-sm-none small-title">
    <h2>Manual Therapy</h2>
</div>
<div id='intro'>
    <p>This is the “hands-on” treatment that can help you get better. Massage and manipulations are versions of manual therapy, as is joint mobilisation. Other versions of manual 
        therapy are the physiotherapist moving a stiff or weak limbs (passive movements), and some stretching techniques.</p>
</div>
<div id='mobilisation'>
    <h2>Mobilisation, Manipulation and Massage</h2>
    <p>Mobilisation treatment is the technique of moving joints in specific directions and at different speeds to regain normal movement. It is done at a level of intensity you are 
        comfortable with.  Sometimes joints can feel a bit stiff or tender at the start of treatment, but this usually passes as the joint moves more easily. Think about it as a rusty
         hinge, that loosens up as you move it backwards and forwards. For other joints, mobilisation can help the joint “track” better – this means it is gliding and moving the best
          way. This helps to restore optimal motion, function, and/or to reduce pain.</p>
    <p>Manipulation is a sudden “thrust” to a joint. This is the technique where you can hear a loud “crack”. Whilst trained and qualified to use manipulation techniques, we at 
        Northam Physiotherapy rarely use them. We feel that the risk of injury with a vigorous joint movement is not justified for the limited results it gives.  Our years of 
        experience have shown us that gentler mobilisation (see more below) is more effective in gaining improvements.</p>
    <p>Massage can be helpful in gaining movement and improved blood supply to soft tissues (muscles and ligaments).  The right massage technique can be very helpful in loosening 
        tight muscles and reducing your discomfort. In physiotherapy this is sometimes called Specific Soft-Tissue Techniques.  This is to differentiate it from the more general
         massage we can have for relaxation or wellbeing from massage therapists and beauticians.  Specific physiotherapy massage for an injury or painful problem can gain quicker 
         results with your injury or pain.  On its own, physiotherapy massage can help you feel a bit better, but is even more effective as part of a comprehensive physiotherapy 
         treatment.</p>
</div>
<div id='other'>
    <h2>Other Manual Therapies</h2>
    <p>Some tight muscles respond well to physiotherapy hands-on techniques that improve flexibility.  Passive movements of weak or stiff limbs can also help restore function and 
        normal movement.</p>
</div>
<div id='conditions'>
    <h2>What conditions would we use manual therpay for?</h2>
    <p>Manual therapy is used for many different conditions, some of the more common ones are listed below:</p>
    <ul>
        <li>Neck pain – osteoarthritis, posture, whiplash, headaches</li>
        <li>TMJ problems</li>
        <li>Shoulder pain – frozen shoulder, impingement, ACJ dysfunction, post-surgery shoulders</li>
        <li>Thoracic pain – postural problems, OA, upper back pain</li>
        <li>Low back pain – discogenic pain, OA, facet joint arthropathy, spinal stenosis, post-surgery, low back, SIJ dysfunction</li>
        <li>Hip pain – OA, greater trochanteric pain syndrome (GTPS), post-surgery</li>
        <li>Knee pain- OA, ligament injuries, meniscal tears, meniscal impingement, patella-femoral pain syndrome, post-surgery knees including knee replacement, ligament repair, arthroscopy</li>
        <li>Ankle – ankle sprains, ligament injuries, OA, post-surgery</li>
    </ul>
    <button id='bottombackbutton' mdbBtn color="black" outline="true" class="waves-light" mdbWavesEffect (click)="btnBackClick();">
        <mdb-icon fas icon="chevron-left" class="left"></mdb-icon>Treatments
    </button>
</div>

