<div cwdjParallax [config]="{parallaxInitVal: -300, parallaxRatio: -1}" class="view">
    <div class="flex-center rgba-stylish-slight d-none d-md-inline">
        <div class="text-white text-center py-5 px-4 my-5">
              <h2 class="card-title h1-responsive pt-3 mb-5 font-bold"><strong>Shoulder pain</strong></h2>
              <a pageScroll mdbBtn color="white" outline="true" class="waves-light" mdbWavesEffect [fragment]="'adhesivecapsulitis'">
                <mdb-icon fas icon="user-times" class="left"></mdb-icon>Adhesive capsulitis
              </a>
              <a pageScroll mdbBtn color="white" outline="true" class="waves-light" mdbWavesEffect [fragment]="'impingement'">
                <mdb-icon fas icon="compress-alt" class="left"></mdb-icon>Impingement
              </a>
              <a pageScroll mdbBtn color="white" outline="true" class="weaves-light" mdbWavesEffect [fragment]="'instability'">
                <mdb-icon fas icon="balance-scale-right" class="left"></mdb-icon>Instability
              </a>
              <button mdbBtn color="white" outline="true" class="waves-light" mdbWavesEffect (click)="btnBackClick();">
                <mdb-icon fas icon="chevron-left" class="left"></mdb-icon>Conditions
              </button>
        </div>
    </div>
</div>
<div class="d-sm-none">
    <img src="../../assets/images/chronicpain.jpg" class="img-fluid" alt="Image of acupuncture needles in an arm">
</div>
<div class="d-sm-none small-title">
    <h2>Shoulder pain</h2>
</div>
<div id='intro'>
    <p>This is one of the most common conditions that we see at Northam Physiotherapy. Shoulder problems can be very painful, particularly at night 
        and finding a comfortable position can be difficult.</p>
    <p>Shoulder problems usually fall in to 3 categories:</p>
        <ul>
            <li>Adhesive capsulitis (frozen shoulder)</li>
            <li>Impingement</li> 
            <li>Instability</li> 
        </ul>
</div>
<div id='adhesivecapsulitis'>
    <h2>Adhesive capsulitis</h2>
    <p>This is a condition where the shoulder joint effectively stiffens up so that it becomes difficult to move. It is caused by a thickening of the connective tissue around the shoulder called the shoulder joint capsule.</p>
    <p>The reason why this happens is unclear, but it can occur with people who have diabetes, have had a recent injury or surgery. It tends to affect ladies more than men. If there is a continued irritation of the shoulder (see impingement) then this can lead to adhesive capsulitis. </p>
    <h3>Treatment</h3>
    <p>The 1st step is an accurate diagnosis to rule out other conditions of the shoulder. Many patients tell us that they have a frozen shoulder but in fact they are often suffering with an impingement problem. </p>
    <p>Treatment is focused on gently trying to restore some movement to the shoulder with stretches and manual therapy. This can be very effective, and improvements seen quickly. In some cases, a steroid injection can be helpful, however this needs to be then followed up with physiotherapy. </p>
</div>
<div id='impingement'>
    <h2>Impingement</h2>
    <p>This is the most common shoulder condition and can be very painful. Unlike adhesive capsulitis the shoulder will move normally, however can be painful, especially when reaching overhead. It affects the rotator cuff tendon, which is the rubbery tissue that connects the muscles around your shoulder joint to the top of your arm.</p>
    <p>The condition is caused by one or more of the tendons of the rotator cuff in the shoulder being rubbed or catching on nearby bone as the arm is lifted. This is usually due to a postural problem and tends to affect ladies more than men.</p>
    <p>Symptoms include:</p>
        <ul>
            <li>Pain at the top of the shoulder</li>
            <li>Pain that worsens as the arim is lifted (and it should go as high as the other arm)</li>
            <li>Pain at night</li>
            <li>Weakness in the arm</li>
        </ul>
    <p>Note - it should not feel stiff, if it does then it may be adhesive capsulitis.</p>
    <h3>Treatment</h3>
    <p>Shoulder impingement is caused by irritation, so it is important to identify what is causing it. If you were to scratch the palm of you hand for a minute it would not be painful, but if you kept doing it for days, weeks or months it could become very sore and this is similar to what happens.</p>
    <p>Treatment involves removing irritating activities, usually some postural exercises to deal with the underlying cause and then a gentle progressive loading programme to help the tendons to recover. (See section on <a (click)="btnTendinopathyClick()">tendinopathy</a>).</p>
</div>
<div id='instability'>
    <h2>Instability</h2>
    <p>This is a condition where the lining of the shoulder joint becomes stretched, torn or detached allowing the joint to completely or partially dislocate. It is usually caused by a traumatic accident and usually occurs in younger adults. </p>
    <p>There is a second classification called atraumatic where the instability is caused by a problem since birth, after surgery or could happen after damage or rupture of one or more of the rotator cuff tendons.</p>
    <h3>Treatment</h3>
    <p>The main aim of physiotherapy treatment is to prevent re-occurrence, reduce pain and increase strength. This usually involves education, postural re-training, specific muscle strengthening and stretching. </p>                        
    <button id='bottombackbutton' mdbBtn color="black" outline="true" class="waves-light" mdbWavesEffect (click)="btnBackClick();">
        <mdb-icon fas icon="chevron-left" class="left"></mdb-icon>Conditions
    </button>
</div>

