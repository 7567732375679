<div cwdjParallax  [config]="{parallaxInitVal: -25, parallaxRatio: -1}" class="view">
  <div  class="flex-center rgba-stylish-slight d-none d-md-inline">
        <div id='pagemenu' class="text-white text-center py-5 px-4 my-5">
            <h2 class="card-title h1-responsive pt-3 mb-5 font-bold d-none d-md-inline d-lg-block"><strong id='heroTitle'>Welcome to Northam Physiotherapy</strong></h2>
            <div class="d-none d-xl-block btns">
              <a pageScroll mdbBtn color="white" outline="true" class="waves-light d-none d-lg-inline" mdbWavesEffect [fragment]="'covid'">
                <mdb-icon fas icon="medkit" class="left"></mdb-icon>Covid-19
              </a>
              <a pageScroll mdbBtn color="white" outline="true" class="waves-light d-none d-lg-inline" mdbWavesEffect [fragment]="'whatisphysio'">
                <mdb-icon fas icon="question" class="left"></mdb-icon>What is physiotherapy?
              </a>              
              <a pageScroll mdbBtn color="white" outline="true" class="waves-light d-none d-lg-inline" mdbWavesEffect [fragment]="'chooseus'">
                <mdb-icon fas icon="user-friends" class="left"></mdb-icon>Why choose us?
              </a>
              <a pageScroll mdbBtn color="white" outline="true" class="waves-light d-none d-lg-inline" mdbWavesEffect [fragment]="'booking'">
                <mdb-icon fas icon="calendar-alt" class="left"></mdb-icon>Booking appointments
              </a>
              <a pageScroll mdbBtn color="white" outline="true" class="waves-light" mdbWavesEffect [fragment]="'pain'">
                <mdb-icon fas icon="bolt" class=left></mdb-icon>Pain
              </a>
              <a pageScroll mdbBtn color="white" outline="true" class="waves-light d-none d-lg-inline" mdbWavesEffect [fragment]="'whatotherssay'">
                <mdb-icon fas icon="bullhorn" class="left"></mdb-icon>Reviews
              </a>
            </div>
        </div>
  </div>
</div> 
<div class="d-sm-none">
  <img src="../../assets/images/room.png" class="img-fluid"  alt="Image of the clinic room at Northam Physiotherapy">
</div>
  <div class="d-sm-none small-title">
  <h2>Welcome to Northam Physiotherapy</h2>
</div>
<div class="row" id='covid'>
  <div class="col-md-9">
    <h2>COVID-19</h2>
    <p>Northam Physiotherapy is open for appointments. However, the process has changed a bit at the moment due to the Covid pandemic regulations.</p>
    <p>First appointments are offered in a two-part consultation, so that we reduce the amount of time we are in the same room, while still giving you 
      the same level of service.  The first part is when we hear about your problem in detail, and this is currently virtual (via video) or on the telephone.  
      The second part is a face-to-face session in our clinic.  This two-part first assessment works well to meet your physio needs, and reduces the risk of transmitting the virus.</p>
    <p>This is following guidance published by the Chartered Society of Physiotherapy (CSP) and Physio-First (Physiotherapists in Private Practice). The idea
       is to reduce the amount of time spent in the same room, as this will reduce risk of transmission if either person is infected but not showing symptoms.
         We are, of course, unable to see anyone who is currently infected or isolating.</p>
    <p>For some people, we can complete the assessment and treatment over the computer link, but this is the exception rather than the norm.  Most people recover better
       with some hands-on treatment, and providing we follow the guidelines, this is relatively safe.</p>
    <p>This is what to expect when you come to see us:</p>
    <ul>
      <li>When you arrive, we will meet you outside or at the door. We will try to be waiting for you so you don’t have to touch the doorbell. 
        We ask you to arrive on time (not early) so that we can let you in promptly.  During the pandemic, it is not advisable to come early and wait indoors here. 
        Also, it isn’t possible to bring anyone with you into the session.</li>
      <li>We both need to be wearing facemasks from the front door.  We will bring you in, check your temperature and ask you to fill out a COVID 19 screening form 
        which also allows us trace contacts if needed.</li>
      <li>During treatment we will be wearing PPE consisting of mask, gloves and plastic apron. You are required to wear a mask. If you do not have one, we can provide one.
         Where we can, we will keep 2 metres apart, but obviously this will only be some of the time, but it all helps.</li>
      <li>We clean and air the room between patients, and leave some time between appointments, again to reduce risk of transmission.</li>
    </ul>
  </div>
  <div class="fb-page col-md-3 d-none d-md-inline" data-href="https://www.facebook.com/Northam-Physiotherapy-571546923035985" data-tabs="timeline" data-width="" data-height="" data-small-header="true" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true"><blockquote cite="https://www.facebook.com/Northam-Physiotherapy-571546923035985" class="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/Northam-Physiotherapy-571546923035985">Northam Physiotherapy</a></blockquote></div>
</div>
<div id='whatisphysio'>
    <h2>What is Physiotherapy</h2>
    <p>Physiotherapy is a well-established treatment approach that helps many physical problems.  Physiotherapy can restore movement and function when someone is affected by injury,
       illness, pain or disability. Physiotherapy uses “physical means” to help treat problems: that means anything that is not drugs or surgery. Most physiotherapy treatments 
       involve “hands-on” treatment such as specific massage and joint movements, customised exercises, and advice about how to cope with current restrictions and how to get better.</p>
    <p>Physiotherapy can help maintain activity, improve wellbeing and good health for people of all ages. Most patients come to physiotherapy for help with a problem that is painful,
       and we can help a lot with that. Sometimes, even the best physiotherapy can’t “cure”, but does help patients to manage pain and reduce the effects of a disease or problem. 
       Physiotherapy treatment helps the body with recovery, enabling people to stay in work or get back to sports and hobbies. For many older people, physiotherapy can be vital to 
       maintain mobility and independence, as well as reducing aches and pains.</p>
</div>
<div id='howdifferent'>
  <h2>How is Physiotherapy different to Osteopathy, Chiropractic treatment, and Sports Therapy?</h2>
  <p>We are often asked what is the difference between physiotherapy and other therapies. There are some similarities, but also some fundamental differences. We believe that 
    physiotherapy is the best approach for most people (as we would, of course), and has advanced training starting at degree level, and the most medical education background.</p>
  <p>Physiotherapy is the combination of many “physical” approaches to gain a comprehensive understanding of how the body moves and works.  Physiotherapists are the experts in how 
    muscles and joints are affected by injury and habitual poor movement patterns.  We do use massage like massage therapists, and we use joint mobilisation or manipulations like 
    chiropractors, but always in a combined treatment approach. Physiotherapy also tends to encourage and educate you to maintain your recovery independently, and have control over 
    your problem so that you are not reliant on always coming back for more treatment.  The NHS employs thousands of physiotherapists, and almost none of our rival professions.</p>
  <p>Osteopaths and Chiropractors are the experts at spinal joint manipulation, but don’t usually treat muscles or use home exercises. A central part of their assessment is that 
    the body is out of true alignment and needs regular adjustment over years to correct this.</p>
  <p>Sports Therapists are massage and exercise therapists, but usually not qualified to the same level as physiotherapists in medical problems or more complicated injuries.  
    As the name suggests, they tend to work with sports teams and help with basic sports injuries such as muscle strains.</p>
  <p>Physical Therapists – this is the term they use in the USA for physiotherapists. In this country it is a similar level of training as sports therapists.</p>
  <p>Massage Therapists can be very helpful for relaxing or invigorating massage.  They would not be qualified to analyse an injury or your movement faults, and usually are not 
    allowed to mobilise your joints.</p>
</div>
<div id='helpedproblems'>
  <h2>What problems can be helped with physiotherapy?</h2>
  <p>At Northam Physiotherapy we are experts at the assessment and treatment of conditions which affect the musculoskeletal system. The common problems we help often include but are
     not limited to:</p>
  <ul>
    <li>Low back pain including sciatica</li>
    <li>Neck pain</li>
    <li>Shoulder pain</li>
    <li>Sports injuries</li>
    <li>Postural problems</li>
    <li>Whiplash injuries</li>
    <li>Osteoarthritis and other joint problems</li>
    <li>Tendon and muscle strains (tennis elbow, golfers’ elbow, Achilles tendonitis etc)</li>
    <li>Overuse injuries</li>
    <li>Soft tissue injuries</li>
    <li>Nerve problems</li>
    <li>Rehabilitation after orthopaedic surgery</li>
    <li>Rehabilitation after a fracture</li>
  </ul>
</div>
<div id='chooseus'>
  <h2>Why choose Northam Physiotherapy?</h2>
  <p>Husband-and-wife team Stuart and Jane are both Chartered Physiotherapists and registered with the Health and Care Professions Council.  They have nearly 50 years of 
    experience between them. This has been gained from many years working within the NHS, and also the MOD, sports clubs and private practice. They set up Northam Physiotherapy 
    in 2012 in response to demand from family, friends and neighbours who had benefitted from help. The clinic has continued to develop since then mostly by personal 
    recommendation from satisfied clients (See our Reviews section).</p>
  <p>We are experts in what we do. We have both been very pro-active in our professional education and have completed many post-graduate courses to further our knowledge and 
    understanding. We have worked alongside specialists in their areas at different stages of our careers. Read our professional profiles to find out more about us. We are 
    committed to providing the very best care we can for all our patients.</p>
  <p>Patients often tell us that they expect to have to live with some level of pain, especially when they are a little older. At Northam Physiotherapy we strongly believe that 
    we should be pain-free, or at least comfortable, most of the time. Pain often means that something physical is not working properly. Sometimes it is a small problem that 
    becomes very painful, and this makes it hard for you or your GP to identify the cause of the pain. It could be that a joint has become stiff, or a muscle is a bit weak to 
    support an area or movement.</p>
  <p>Using our detailed expert analysis, we can identify the cause of the problem and provide you with customised treatment to correct the problem. A good example of this is 
    a painful arthritic knee.  The knee does have some wear and tear, but if it moves well with all the support muscles working as they should, then the pain is often much reduced 
    and the progress of the wear and tear is slowed.</p>
  <p>At the core of what we do is the patient’s involvement in their own care, through education, awareness, empowerment and participation in their treatment.</p>
  <p>We have a dedicated clinic room in our house for our physiotherapy sessions.  It is private and quiet and we have all the equipment we need to treat you.  Parking is easy 
    on the road at the front of the house.  There is only a small step into the house, and a gentle slope up the driveway.  For people with mobility needs, we are happy to greet 
    you at the gate and help you come in.</p>
</div>
<div id='booking'>
  <h2>How do I book an appointment?</h2>
  <p>The easiest way to do this is to telephone us.  We have an answering machine so you can leave a message. We will call you back to briefly discuss your condition and arrange 
    an appointment.</p>
  <p>We pride ourselves on being able to offer appointments soon after your call or even the same day, and are happy to see you evenings and weekends.  There is no additional 
    cost for evening and weekend appointments.</p>
  <p>Call us today on 01237 476734. We may be busy treating patients, so leave a message and we will call you back. If you do get the answerphone please speak slowly and carefully,
     particularly when leaving your name and telephone number as this will ensure that we can call you back. Sometimes, messages can be muffled, so it often helps to say your 
     phone number twice.</p>
</div>
<div id='firstappt'>
  <h2>What happens when I attend my first appointment?</h2>
  <p>Please see the section above about Covid related restrictions</p>
  <p>We will ask you to tell us all about the problem, such as where the problem is and how it is affecting you. We then carry out a physical assessment such a movement analysis. 
    This helps us to identify the source of your problem. Then we have a discussion to explain what is going on and together agree a plan to help.
  </p>
  <p>We will devise an exercise programme for you to do at home, which will help you maintain the benefits from treatment. We are experts in manual therapy and where appropriate
     we will provide some ‘hands on’ treatment which can be helpful to loosen stiff joints or stretch tight muscles.
  </p>
</div>
<div id='pain'>
  <h2>Is physiotherapy treatment painful?</h2>
  <p>Some people worry that physiotherapy tests or treatment will hurt, but we will be very careful to avoid causing you extra discomfort.  During any treatment or a test movement
     we make sure you are comfortable, and that you know what is happening and consent to any treatment or test.</p>
  <p>We aim to in partnership with you, and ensure you are comfortable and in control of what is happening. Sometimes it can be uncomfortable when first moving a stiff joint,
     or when starting to work a weak muscle. But grading this at the right level should help minimise this, and we always ask you to what it feels like. It’s definitely not a 
     case of “no pain, no gain”, in fact we often say ‘more pain equals no gain’. There are many ways that we can help reduce your pain.
  </p>
</div>
<div id='painfulproblem'>
  <h2>Why have I got a painful problem?</h2>
  <p>There are many different reasons why we get aches and pain and injuries. Part of the physiotherapy assessment is to work out what is going on to cause your specific problem.</p>
  <p>Patients often believe that because they are active, they should automatically have good muscles. This is true up to a point, as the effect of vigorous exercise is that many 
    muscles get stronger.  However, these muscles will also gradually shorten and eventually feel tighter. During our lifetime, our muscles and joints can tend to get stiffer, 
    and this eventually can cause discomfort or joint wear.</p>
  <p>Stretching regularly is a start, but sometimes isn’t enough. Not all muscle groups strengthen at the same speed – there can still be some muscles that stay weaker than others. 
    A physiotherapy analysis with us can help this, and treatment can result in improved muscle and joint function.</p>
  <p>Sitting for work or travel is also a common cause of physical aches and pain, and these are sometimes very painful problems. Humans are essentially hunter-gathers, designed to 
    be moderately active all day with lots of complicated movements that allow us to run, climb, swim, use tools and many other daily activities. If we don’t use our bodies like this,
     and most of us don’t, then we start to get problems. Modern lifestyles have become more sedentary and do not help us stay healthy, active and pain free.  Small changes like 
     getting up often and walking around the office or kitchen can help make a difference.</p>
  <p>And of course, we can all have an accident or fall that causes an injury.  Physiotherapy can help with the recovery of this. Sometimes you may need the help of your GP or the 
    hospital as well, and physiotherapy then is just part of the medical team that gets you better.</p>
</div>
<div id='treatmentlength'>
  <h2>How long will it take me to get better and will I need more treatment?</h2>
  <p>We can give you a good estimate of how long it will take for you to see results. This often depends on how long you have had the problem, and how strong and persistent are the
     symptoms. The body recovers slowly, even when you are doing everything right, so perseverance is often needed.  You probably won’t need our help for the whole time of your 
     recovery journey, although many people find it helpful to keep coming to see us until they are confident they are “winning”.</p>
  <p>Some people like to have a check-up sometimes, and of course we are happy to provide that.  Most people, however, get better and then get on with their lives with a few new 
    good habits to keep the problem from coming back. Some conditions are long-term, but with the right management plan from us, you can stay as active and comfortable as possible 
    independently.</p>
</div>
<div id='whatotherssay'>
  <h2>What others say about us</h2>
  <p>We are rated 5 <mdb-icon fas icon="star" class="green-text"></mdb-icon> consistantly on Google Reviews.</p>
  <h4>Andrew Woolf 2019</h4>
  <mdb-icon fas icon="star" class="green-text"></mdb-icon><mdb-icon fas icon="star" class="green-text"></mdb-icon><mdb-icon fas icon="star" class="green-text"></mdb-icon><mdb-icon fas icon="star" class="green-text"></mdb-icon><mdb-icon fas icon="star" class="green-text"></mdb-icon>
  <p>I have visited Stuart on 2 different occasions due to running related issues. Stuart explains the problem extremely well along with developing a self help recovery plan. Both issues were resolved through the self help and minimal visits required with Stuart. I would recommend Stuart very highly.</p>
  <h4>Ruben Stacey 2018</h4>
  <mdb-icon fas icon="star" class="green-text"></mdb-icon><mdb-icon fas icon="star" class="green-text"></mdb-icon><mdb-icon fas icon="star" class="green-text"></mdb-icon><mdb-icon fas icon="star" class="green-text"></mdb-icon><mdb-icon fas icon="star" class="green-text"></mdb-icon>
  <p>Jane and Stuart are very accommodating in providing professional treatment to me from both sports injuries and general posture. Everything is explained in layman terms and in detail. Not only do they treat the issue, they also provide exercises so that I can continue to improve between treatments. I would highly recommend Northam Physiotherapy.</p>
  <p>See more reviews <a href="https://www.google.com/search?sxsrf=ALeKk03wOrIWsTmzrK-tCxoGlS0hZbzm1w%3A1597230142007&ei=PcwzX8vxPPmo1fAPkImFMA&q=northam+physiotherapy&gs_ssp=eJzj4tVP1zc0TDaqNIkvLs8yYLRSNagwsTBLNk5JTU5Ls0wxSzE2tzKosExJMbRMM0i1tEgxTks2TPISzcsvKslIzFUoyKgszswvyUgtSiyoBACF-xj6&oq=northam+ph&gs_lcp=CgZwc3ktYWIQAxgBMgsILhDHARCvARCTAjIICC4QxwEQrwEyAggAMgIIADICCAAyAggAMgIIADICCAAyAggAMgIIADoECAAQRzoECCMQJzoHCAAQsQMQQzoECAAQQzoHCCMQ6gIQJzoFCAAQkQI6DgguELEDEIMBEMcBEKMCOgsILhCxAxDHARCjAjoICC4QxwEQowI6CAgAELEDEIMBOgoILhDHARCjAhBDOgQILhBDOg0ILhDHARCjAhBDEJMCOgcILhCxAxBDOg4ILhDHARCvARCRAhCTAjoFCC4QsQM6CAguELEDEIMBOgIILjoLCC4QsQMQxwEQrwE6DgguELEDEIMBEMcBEK8BOgUIIRCgAVCTFljSRmDuU2gDcAF4AoABuQGIAcQUkgEEMTYuOZgBAKABAaoBB2d3cy13aXqwAQrAAQE&sclient=psy-ab#">here</a></p>
</div>