<div cwdjParallax [config]="{parallaxInitVal: -125, parallaxRatio: -1}" class="view">
    <div class="flex-center rgba-stylish-slight d-none d-md-inline">
        <div class="text-white text-center py-5 px-4 my-5">
              <h2 class="card-title h1-responsive pt-3 mb-5 font-bold"><strong>Acupuncture</strong></h2>
              <a pageScroll mdbBtn color="white" outline="true" class="waves-light" mdbWavesEffect [fragment]="'workforme'">
                <mdb-icon fas icon="question" class="left"></mdb-icon>Will it work for me?
              </a>
              <a pageScroll mdbBtn color="white" outline="true" class="waves-light" mdbWavesEffect [fragment]="'itinvolves'">
                <mdb-icon fas icon="comment-medical" class="left"></mdb-icon>What does it involve?
              </a>
              <a pageScroll mdbBtn color="white" outline="true" class="waves-light" mdbWavesEffect [fragment]="'notfor'">
                <mdb-icon fas icon="times-circle" class="left"></mdb-icon>Who should not have acupunture?
              </a>
              <a pageScroll mdbBtn color="white" outline="true" class="waves-light" mdbWavesEffect [fragment]="'risks'">
                <mdb-icon fas icon="balance-scale" class="left"></mdb-icon>What are the risks?
              </a>
              <button mdbBtn color="white" outline="true" class="waves-light" mdbWavesEffect (click)="btnBackClick();">
                <mdb-icon fas icon="chevron-left" class="left"></mdb-icon>Treatments
              </button>
        </div>
    </div>
</div>
<div class="d-sm-none">
    <img src="../../assets/images/accupuncture.JPG" class="img-fluid" alt="Image of acupuncture needles in an arm">
</div>
<div class="d-sm-none small-title">
    <h2>Acupuncture</h2>
</div>
<div id='intro'>
    <p>Acupuncture is now a mainstream treatment to help reduce pain.  It is used in hospitals and hospices and many GP practices throughout the country. It has been used
         by physiotherapists for many years to treat pain. We offer acupuncture at Northam Physiotherapy as part of a physiotherapy treatment package.</p>
    <p>At first it might seem a bit strange to use needles to help us recover, but there is reasonable research evidence that acupuncture works for many people.  It tends to 
        work best when you are doing other things as well that help you recover such as your home exercises.</p>
    <p>Acupuncture is a treatment derived from ancient Chinese medicine. In Chinese medicine, acupuncture is used for all sorts of medical problems, but in western medicine it
         is more commonly used to help reduce pain and loosen tight muscles. It helps by activate the body’s own pain-relieving systems such as stimulating the release of endorphins.</p>
</div>
<div id='workforme'>
    <h2>Will acupuncture work for me?</h2>
    <p>Research has shown that acupuncture can help to relieve pain.  Like other forms of therapy, it does not work for everyone.  People can respond to treatment in different ways
         and at different rates.  Your physiotherapist will select a treatment best suited to your individual needs.  There is no set rule about the number of treatment sessions you
          will need, but four is often the minimum needed to see an improvement.  It usually takes several more sessions to maintain this improvement.</p>
</div>
<div id='itinvolves'>
    <h2>What does acupuncture treatment involve?</h2>
    <p>You will be in a comfortable position on the treatment couch.  The needles will be inserted into the skin in different areas in the body.  Some needles may be in a different
         part of the body than the painful area.</p>
    <p>The needles are single-use sterile fine needles encased inside a protective plastic tube. The tube will be pressed firmly on the skin and the needle end tapped to be inserted.
        Usually, you mostly feel the plastic tube even when this is removed. Anything between one and 20 needles can be used.</p>
    <p>Once the needles are in place, you may feel a mild ache or numbness, or a warm heavy sensation. This is a good sign and means that the body has started it’s pain relieving 
        process.  The physiotherapist often stimulates the needles by gently moving them- sometimes this is a bit uncomfortable, but it helps to make the treatment more effective.</p>
    <p>The needles stay in place for 10-40 minutes depending on the type of problem.</p>
    <p>Before any needles are used, you will need to give informed consent.  This starts with the discussion with your physiotherapist about the treatment, and involves reading and
         understanding this information.  If you are happy to proceed, then you will sign a consent form. At any time, you can change your mind and the treatment will immediately 
         stop and all the needles will be removed</p>
</div>
<div id='notfor'>
    <h2>Is there anyone who should not have acupuncture?</h2>
    <p>You shouldn’t have acupuncture if you have an unstable heart condition, are allergic to metal, or have a blood clotting disorder such as haemophilia.</p>
    <p>If you are pregnant or trying to get pregnant, then this clinic will not treat you with acupuncture. A specialist therapist in pregnancy conditions may consider
         it if you would like to explore this option.  We also will not do acupuncture treatment if you are unwell or have an infection.</p>
    <p>We will take extra precautions if you have epilepsy, fragile skin, hepatitis, diabetes, pacemaker, or take anticoagulant drugs or oral steroid.</p>
    <p>If you have a needle phobia, then acupuncture is probably not for you.</p>
</div>
<div id='risks'>
    <h2>What are the risks from acupuncture?</h2>
    <p>Generally, the risks are low when acupuncture is administered by a trained professional.  However, there are some risks that you should be aware of:</p>
    <ul>
        <li>Some people feel a bit sleepy or lightheaded after treatment.  You should not drive or operate machinery if this is how you feel.  If you do feel sleepy, then it can be very helpful to your recovery to have a sleep as soon as you can.</li>
        <li>Some people find it too uncomfortable- either one particular needle, or the whole treatment process.  In these cases, the therapist would try some different needle sites or less stimulation of the needles, or agree with the patient that this is not the treatment for them.</li>
        <li>There may be some minor bruising or bleeding after the treatment.</li>
        <li>Less than 3 people in a 100 find that their symptoms get worse at first.  This can be a good sign, suggesting that your body is responding to the treatment, but make sure you tell us before we continue treatment.</li>
        <li>Rare but serious: about one in 200,000 treatments could lead to a serious problem such as a punctured lung.  You must seek medical help immediately if you feel breathless or have chest pain after an acupuncture treatment that has used needles over the chest or thoracic area.</li>
    </ul>
    <p>If you have any concerns of more questions, just ask and we’ll be happy to explain, and help make the right decision with you.</p>
    <button id='bottombackbutton' mdbBtn color="black" outline="true" class="waves-light" mdbWavesEffect (click)="btnBackClick();">
        <mdb-icon fas icon="chevron-left" class="left"></mdb-icon>Treatments
    </button>
</div>

