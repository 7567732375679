import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-conditions',
  templateUrl: './conditions.component.html',
  styleUrls: ['./conditions.component.scss']
})
export class ConditionsComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  btnTendinopathyClick = function () {
    this.router.navigateByUrl('/conditions/tendinopathy');
  }

  btnSportsClick = function () {
    this.router.navigateByUrl('/conditions/sportsinjury');
  }

  btnShoulderClick = function () {
    this.router.navigateByUrl('/conditions/shoulderpain');
  }

  btnChronicClick = function () {
    this.router.navigateByUrl('/conditions/chronicpain');
  }

  btnLowBackClick = function () {
    this.router.navigateByUrl('/conditions/lowbackpain');
  }
}
