import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-manualtherapy',
  templateUrl: './manualtherapy.component.html',
  styleUrls: ['./manualtherapy.component.scss']
})
export class ManualtherapyComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  btnBackClick = function () {
    this.router.navigateByUrl('/treatments')
  }

}
