<!--Navbar-->
<mdb-navbar SideClass="navbar navbar-dark navbar-expand-md  blue" class="w-100">

    <!-- Navbar brand -->
    <mdb-navbar-brand>Northam Physiotherapy</mdb-navbar-brand>

    <!-- Collapsible content -->
    <links>

        <!-- Links -->
        <ul class="navbar-nav mr-auto">
            <li class="nav-item d-none d-md-inline">
                <a class="nav-link waves-light" mdbWavesEffect routerLink="home">Home</a>
            </li>
            <li class="nav-item d-none d-md-inline">
                <a class="nav-link waves-light" mdbWavesEffect routerLink="treatments">Treatments</a>
            </li>
            <li class="nav-item d-none d-md-inline">
                <a class="nav-link waves-light" mdbWavesEffect routerLink="conditions">Conditions</a>
            </li>
            <li class="nav-item d-none d-md-inline">
                <a class="nav-link waves-light" mdbWavesEffect routerLink="aboutus">About us</a>
            </li>
            <li class="nav-item d-none d-md-inline">
                <a class="nav-link waves-light" mdbWavesEffect routerLink="links">Useful links</a>
            </li>
            <li class="nav-item dropdown d-md-none" dropdown>
                <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect>
                Home<span class="caret"></span></a>
                <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
                    <a class="dropdown-item waves-light" mdbWavesEffect routerLink='home' [fragment]="'covid'">COVID-19</a>
                    <a class="dropdown-item waves-light" mdbWavesEffect routerLink='home' [fragment]="'whatisphysio'">What is physiotherapy?</a>
                    <a class="dropdown-item waves-light" mdbWavesEffect routerLink='home' [fragment]="'chooseus'">Why choose us?</a>
                    <a class="dropdown-item waves-light" mdbWavesEffect routerLink='home' [fragment]="'booking'">Booking appointments</a>
                    <a class="dropdown-item waves-light" mdbWavesEffect routerLink='home' [fragment]="'pain'">Pain</a>
                    <a class="dropdown-item waves-light" mdbWavesEffect routerLink='home' [fragment]="'whatotherssay'">Reviews</a>
                </div>
            </li>
            <li class="nav-item dropdown d-md-none" dropdown>
                <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect>
                Treatments<span class="caret"></span></a>
                <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
                    <a class="dropdown-item waves-light" routerLink='treatments' [fragment]="'exercise'" mdbWavesEffect>Exercise</a>
                    <a class="dropdown-item waves-light" routerLink='treatments' [fragment]="'manual'" mdbWavesEffect>Manual Therapy</a>
                    <a class="dropdown-item waves-light" routerLink='treatments' [fragment]="'acupuncture'" mdbWavesEffect>Acupunture</a>
                    <a class="dropdown-item waves-light" routerLink='treatments' [fragment]="'electrotherapy'" mdbWavesEffect>Electrotherapy</a>
                </div>
            </li>
            <li class="nav-item dropdown d-md-none" dropdown>
                <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect>
                Conditions<span class="caret"></span></a>
                <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
                    <a class="dropdown-item waves-light" routerLink='conditions' [fragment]="'tendon'" mdbWavesEffect>Tendinopathy</a>
                    <a class="dropdown-item waves-light" routerLink='conditions' [fragment]="'sports'" mdbWavesEffect>Sports injury</a>
                    <a class="dropdown-item waves-light" routerLink='conditions' [fragment]="'shoulder'" mdbWavesEffect>Shoulder pain</a>
                    <a class="dropdown-item waves-light" routerLink='conditions' [fragment]="'chronicpain'" mdbWavesEffect>Chronic pain</a>
                    <a class="dropdown-item waves-light" routerLink='conditions' [fragment]="'back'" mdbWavesEffect>Low back pain</a>
                </div>
            </li>
            <li class="nav-item dropdown d-md-none" dropdown>
                <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect>
                About us<span class="caret"></span></a>
                <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
                    <a class="dropdown-item waves-light" routerLink='aboutus' [fragment]="'stuart'" mdbWavesEffect>Stuart Hall</a>
                    <a class="dropdown-item waves-light" routerLink='aboutus' [fragment]="'jane'" mdbWavesEffect>Jane Clark</a>       
                </div>
            </li>
            <li class="nav-item dropdown d-md-none" dropdown>
                <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect>
                Useful links<span class="caret"></span></a>
                <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
                    <a class="dropdown-item waves-light" routerLink='links' [fragment]="'CSP'" mdbWavesEffect>The Chartered Society of Physiotherapy</a>
                    <a class="dropdown-item waves-light" routerLink='links' [fragment]="'physiofirst'" mdbWavesEffect>PhysioFirst</a>
                    <a class="dropdown-item waves-light" routerLink='links' [fragment]="'hcpc'" mdbWavesEffect>Health and Care Professions Council</a>       
                </div>
            </li>
        </ul>
        <!-- Links -->
    </links>
</mdb-navbar>
<!--/.Navbar-->
<main><router-outlet></router-outlet></main>
<!-- Footer -->
<footer class="page-footer font-small green pt-4">
    <!-- Footer Links -->
    <div class="container-fluid text-center text-md-left">
       <!-- Grid row -->
       <div class="row">
            <!-- Grid column -->
            <div class="col-md-3 mt-md-0 mt-3">
                <!-- Links -->
                <h5 class="text-uppercase">Home</h5>
                <div class="list-group">
                    <a class="list-group-item waves-light" routerLink='home' [fragment]="'covid'" mdbWavesEffect>COVID-19</a>
                    <a class="list-group-item waves-light" routerLink='home' [fragment]="'whatisphysio'" mdbWavesEffect>What is physiotherapy?</a>
                    <a class="list-group-item waves-light" routerLink='home' [fragment]="'chooseus'" mdbWavesEffect>Why choose us?</a>
                    <a class="list-group-item waves-light" routerLink='home' [fragment]="'booking'" mdbWavesEffect>Booking appointments</a>
                    <a class="list-group-item waves-light" routerLink='home' [fragment]="'pain'" mdbWavesEffect>Pain</a>
                    <a class="list-group-item waves-light" routerLink='home' [fragment]="'whatotherssay'" mdbWavesEffect>Reviews</a>
                </div>
            </div>
            <!-- Grid column -->
            <hr class="clearfix w-100 d-md-none pb-3">
            <!-- Grid column -->
            <div class="col-md-3 mb-md-0 mb-2">
            <!-- Links -->
            <h5 class="text-uppercase">Treatments</h5>
            <div class="list-group">
                <a class="list-group-item waves-light" routerLink='treatments' [fragment]="'exercise'" mdbWavesEffect>Exercise</a>
                <a class="list-group-item waves-light" routerLink='treatments' [fragment]="'manual'" mdbWavesEffect>Manual Therapy</a>
                <a class="list-group-item waves-light" routerLink='treatments' [fragment]="'acupuncture'" mdbWavesEffect>Acupunture</a>
                <a class="list-group-item waves-light" routerLink='treatments' [fragment]="'electrotherapy'" mdbWavesEffect>Electrotherapy</a>
            </div>
            </div>
            <!-- Grid column -->
            <hr class="clearfix w-100 d-md-none pb-3">
            <!-- Grid column -->
            <div class="col-md-3 mb-md-0 mb-2">
            <!-- Links -->
            <h5 class="text-uppercase">Conditions</h5>
            <div class="list-group">
                <a class="list-group-item waves-light" routerLink='conditions' [fragment]="'tendon'" mdbWavesEffect>Tendiopathy</a>
                <a class="list-group-item waves-light" routerLink='conditions' [fragment]="'sports'" mdbWavesEffect>Sports injury</a>
                <a class="list-group-item waves-light" routerLink='conditions' [fragment]="'shoulder'" mdbWavesEffect>Shoulder pain</a>
                <a class="list-group-item waves-light" routerLink='conditions' [fragment]="'chronicpain'" mdbWavesEffect>Chronic pain</a>
                <a class="list-group-item waves-light" routerLink='conditions' [fragment]="'back'" mdbWavesEffect>Low back pain</a>
            </div>
            </div>
            <!-- Grid column -->
            <hr class="clearfix w-100 d-md-none pb-3">
            <!-- Grid column -->
            <div class="col-md-3 mb-md-0 mb-2 footer-col-dark">
            <!-- Links -->
            <h5 class="text-uppercase">About us</h5>
            <div class="list-group">
                <a class="list-group-item waves-light" routerLink='aboutus' [fragment]="'stuart'" mdbWavesEffect>Stuart Hall</a>
                <a class="list-group-item waves-light" routerLink='aboutus' [fragment]="'jane'" mdbWavesEffect>Jane Clark</a>              
            </div>
            
            <h5 class="text-uppercase">Useful links</h5>
            <div class="list-group">
                <a class="list-group-item waves-light" routerLink='links' [fragment]="'CSP'" mdbWavesEffect>The Chartered Society of Physiotherapy</a>
                <a class="list-group-item waves-light" routerLink='links' [fragment]="'physiofirst'" mdbWavesEffect>PhysioFirst</a>
                <a class="list-group-item waves-light" routerLink='links' [fragment]="'hcpc'" mdbWavesEffect>Health and Care Professions Council</a>              
            </div>
            </div>
            <!-- Grid column -->
        </div>
        <!-- Grid row -->
    </div>
    <!-- Footer Links -->

    <!-- Copyright -->
    <div class="footer-copyright text-center py-3">© 2021 Copyright:
      <a href="https://www.northamphysiotherapy.com"> northamphysiotherapy.com</a>
      <p>Some images courtesy of <a href="https://unsplash.com/">Unsplash</a> and <a href="https://pixabay.com">Pixabay</a></p>
      <p>Sciatic nerve image courtesy of <a href="scientificanimations.com">Scientific Animations</a>, licensed under <a ahref="https://creativecommons.org/licenses/by-sa/4.0">CC BY-SA 4.0</a></p>
      <p>Version: {{currentApplicationVersion}}</p>
    </div>
    <!-- Copyright -->

</footer>
<!-- Footer -->
