<div cwdjParallax [config]="{parallaxInitVal: -100, parallaxRatio: -1}" class="view">
    <div class="flex-center rgba-stylish-slight d-none d-md-inline">
        <div class="text-white text-center py-5 px-4 my-5">
              <h2 class="card-title h1-responsive pt-3 mb-5 font-bold"><strong>Sports injury</strong></h2>
              <button mdbBtn color="white" outline="true" class="waves-light" mdbWavesEffect (click)="btnBackClick();">
                <mdb-icon fas icon="chevron-left" class="left"></mdb-icon>Conditions
              </button>
        </div>
    </div>
</div>
<div class="d-sm-none">
    <img src="../../assets/images/austris-augusts-52p1K0d0euM-unsplash.jpg" class="img-fluid" alt="Image of acupuncture needles in an arm">
</div>
<div class="d-sm-none small-title">
    <h2>Shoulder pain</h2>
</div>
<div id='intro'>
    <p>Sports injuries are the kinds of injuries that occur either during or as a result of a spot of exercise. While it is possible to injure any part of the body playing sport 
        the term is commonly used to refer to injuries to the musculoskeletal system. We often get asked if we are sports Physiotherapists or do we deal with sports injuries. The 
        answer to this is we are musculoskeletal Physiotherapists, able to manage every kind of condition affecting the muscular and skeletal system. Sports injuries are a small 
        part of this, so yes, we do, but that is just a part of what we do. We both have many years of experience in managing and rehabilitating people back to sport.</p>                        
    <p>Some injuries can be a result of an accident, others from poor training practices or incorrect clothing or equipment. The most common types are sprains and strains. Sprains 
        are injuries to ligaments, the tough bands connecting bones in a joint. A sudden force which takes the ligament past its limit causes injury. Strains or pulled muscles are 
        injuries to muscles and tendons, which anchor muscles to bone. Over stretching a muscle causes tears in muscle fibres and tendons.</p>
    <p>Most sports injuries are preventable by keeping active, maintaining healthy joints and strong muscles. Gentle warmups before exercise warms the body up and increases blood 
        flow to the muscles, getting you more flexible. It is just as important to have an effective stretching programme after exercise.</p>
    <p>There is a common misconception that there are physiotherapists and sports physiotherapists. We all receive the same training. Physiotherapists tend to become more specialised 
        as they gain experience, and we at Northam Physiotherapy are experts in all types of musculoskeletal pains. Jane has a Diploma in Sports Medicine and is an expert in this 
        field, however, treats all conditions. Stuart has years of experience treating all types of musculoskeletal problems from sports injuries, postural problems, osteoarthritis 
        etc.</p>
    <p>Some of the most common conditions that we frequently see are:</p>
    <ul>
        <li>Achilles Tendinopathy</li>
        <li>Plantar fasciitis</li>
        <li>Ankle injuries including ligament sprains</li>
        <li>Anterior knee pain</li>
        <li>Calf/hamstring/quads/groin strains</li>
        <li>ACL/PCL injury/rupture</li>
        <li>Tennis/Golfers elbow</li>
        <li>Delayed onset muscle soreness</li>
    </ul>
    <p>We can help you recover from these injuries through our expert advice, exercise and treatment strategies. We can help you with treatment programmes with milestones to monitor
         your progress and timescales to get back to your previous level of activity.</p>
    <button id='bottombackbutton' mdbBtn color="black" outline="true" class="waves-light" mdbWavesEffect (click)="btnBackClick();">
        <mdb-icon fas icon="chevron-left" class="left"></mdb-icon>Conditions
    </button>
</div>

