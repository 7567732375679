<div cwdjParallax [config]="{parallaxInitVal: -75, parallaxRatio: -1}" class="view">
    <div class="flex-center rgba-stylish-slight d-none d-md-inline">
        <div class="text-white text-center py-5 px-4 my-5">
              <h2 class="card-title h1-responsive pt-3 mb-5 font-bold"><strong>Exercise</strong></h2>
              <a pageScroll mdbBtn color="white" outline="true" class="waves-light" mdbWavesEffect [fragment]="'whichexercises'">
                <mdb-icon fas icon="running" class="left"></mdb-icon>Which exercises?
              </a>
              <a pageScroll mdbBtn color="white" outline="true" class="waves-light" mdbWavesEffect [fragment]="'stopexercise'">
                <mdb-icon fas icon="hand-paper" class="left"></mdb-icon>Stopping exercise
              </a>
              <button mdbBtn color="white" outline="true" class="waves-light" mdbWavesEffect (click)="btnBackClick();">
                <mdb-icon fas icon="chevron-left" class="left"></mdb-icon>Treatments
              </button>
        </div>
    </div>
</div>
<div class="d-sm-none">
    <img src="../../assets/images/julia-ballew-Gh8QHONEHOE-unsplash.jpg" class="img-fluid" alt="Image of a person exercising on bleachers">
</div>
<div class="d-sm-none small-title">
    <h2>Exercise</h2>
</div>
<div id='intro'>
    <p>The right set of exercises can help most problems.  Whether it is a weak muscle or one that is tight, an exercise targeting that specific problem can make all the 
        difference.  Any painful problem usually has a combination of issues with different muscles, and our physiotherapy assessment helps to identify your specific needs.</p>
    <p>Most of us are stiff or tight in some areas, and weak in other areas. Sometimes this tightness can be painful itself, or it cause pain in other areas. Management of a 
        condition often requires stretching or mobilising stiff or tight structures. Stretches need good technique and the right position for effective results. We would teach 
        you the best way to stretch that suits you. It is worth being aware that a muscle can also feel tight if it is a bit weak, so stretching that muscle won’t work until it 
        is stronger – again we would teach you the best way to do this.</p>
    <p>Your physiotherapist is an expert in the prescription of general exercise appropriate to you and your injury or fitness level. As a part of physiotherapy training, we 
        educated in exercise physiology or the science of exercise. This enables your physiotherapist to prescribe exercises targeted to you at that point in time.</p>
</div>
<div id='whichexercises'>
    <h2>What Exercises Should You Do?</h2>
    <p>In general, any version of exercise is better than none.  A targeted set of exercises for your specific problem is even better.  We will prescribe a series of exercises 
        suitable for you at the right level to help your problem.</p>
    <p>It is important that your exercises should not be painful. The saying “no pain, no gain” is not appropriate when recovering from a painful problem.  In fact, research shows
         that pain inhibits muscles from working properly. Often, the fastest way to get better is to do comfortable but moderately challenging exercises every day.</p>
</div>
<div id='stopexercise'>
    <h2>What Happens If (When) You Stop Exercises?</h2>
    <p>Muscles only stay as strong as they need to be for your current level of activity.  This means that if you stop your exercises or become less active generally, then your 
        muscles will get weaker.  When these supporting muscles are weak, it can gradually cause problems to your joints and your movements. Another problem is that other muscles 
        sometimes become tight to compensate for weakness elsewhere.</p>
    <p>Joints also need movement to stay healthy.  There is a lubricating fluid in the joints (synovial fluid) that keeps them healthy. Movement helps this nourishing fluid spread 
        through the joint. This is a bit like oiling a lock and then moving the key in the lock to spread around the oil.  Keeping the body still even for a few hours can causes 
        stiffness and discomfort.  The body likes movement, and your physiotherapy exercises will be the specific movement that your injury or problem needs the most.</p>
    <p>There are also important muscles that help keep our body stable and hold joints still while there is movement somewhere else. These are our stabilising or postural muscles. 
        An example of these working is the movement of fingers while we are writing or typing, while the shoulders and head are kept fairly still. The muscles that keep the shoulder 
        still are our stability muscles or postural muscles.</p>
    <p>Postural muscles are usually close into the joints and deeper than the muscles you can feel under your skin. It is common these days for many people to have weak postural 
        muscles. Even people who do sports and vigorous exercise can have weak postural muscles, and this can be a cause of pain.</p>
    <p>We will assess your muscle function and prescribe the right exercises specific for your needs.  The exercises we teach you will specifically target your problem.  We will 
        practise them together so you are confident you can do them.  They will not require any special weights equipment, and can be performed easily at home.</p>
    <button id='bottombackbutton' mdbBtn color="black" outline="true" class="waves-light" mdbWavesEffect (click)="btnBackClick();">
        <mdb-icon fas icon="chevron-left" class="left"></mdb-icon>Treatments
    </button>
</div>

