<div cwdjParallax [config]="{parallaxInitVal: -75, parallaxRatio: -1}" class="view">
    <div class="flex-center rgba-stylish-slight d-none d-md-inline">
        <div class="text-white text-center py-5 px-4 my-5">
              <h2 class="card-title h1-responsive pt-3 mb-5 font-bold"><strong>Electrotherapy</strong></h2>
              <a pageScroll mdbBtn color="white" outline="true" class="waves-light" mdbWavesEffect [fragment]="'tens'">
                <mdb-icon fas icon="running" class="left"></mdb-icon>TENS machine
              </a>
              <button mdbBtn color="white" outline="true" class="waves-light" mdbWavesEffect (click)="btnBackClick();">
                <mdb-icon fas icon="chevron-left" class="left"></mdb-icon>Treatments
              </button>
        </div>
    </div>
</div>
<div class="d-sm-none">
    <img src="../../assets/images/physiotherapy-5624920_1920.jpg" class="img-fluid" alt="Image of a person exercising on bleachers">
</div>
<div class="d-sm-none small-title">
    <h2>Electrotherapy</h2>
</div>
<div id='intro'>
    <p>This is the use of electrical machines and gadgets to help you recover.  This was a mainstream part of physiotherapy in the 1970s and 1980s.
          Much of this electrotherapy has been discontinued with modern physiotherapy as research evidence tended to show that it didn’t really help much.
           One gadget which can be very helpful is the TENS machine.</p>
</div>
<div id='tens'>
    <h2>TENS machine</h2>
    <p>This is a small gadget that is used to help reduce pain. It has been used effectively for many painful problems for 20 years or so. It doesn’t have any
         effect on healing, but it can help with pain. It works by giving the body a tingling sensation which is generally better than the feeling of pain.  
         Some chemists sell TENS machines (although this tends to be quite expensive), or you can find them online. At the clinic we can trial you with a machine 
         to see if it can help you – a sort of Try-Before-You-Buy.  For some people, it can make all the difference, so it is worth thinking about.</p>
    <button id='bottombackbutton' mdbBtn color="black" outline="true" class="waves-light" mdbWavesEffect (click)="btnBackClick();">
        <mdb-icon fas icon="chevron-left" class="left"></mdb-icon>Treatments
    </button>
</div>

