<div cwdjParallax [config]="{parallaxInitVal: -150, parallaxRatio: -1}" class="view">
    <div class="flex-center rgba-stylish-slight d-none d-md-inline">
        <div class="text-white text-center py-5 px-4 my-5">
              <h2 class="card-title h1-responsive pt-3 mb-5 font-bold"><strong>Sports injury</strong></h2>
              <a pageScroll mdbBtn color="white" outline="true" class="waves-light" mdbWavesEffect [fragment]="'treatment'">
                <mdb-icon fas icon="medkit" class="left"></mdb-icon>Treatment
              </a>
              <button mdbBtn color="white" outline="true" class="waves-light" mdbWavesEffect (click)="btnBackClick();">
                <mdb-icon fas icon="chevron-left" class="left"></mdb-icon>Conditions
              </button>
        </div>
    </div>
</div>
<div class="d-sm-none">
    <img src="../../assets/images/anne-nygard-_W94Eb1iNYc-unsplash.jpg" class="img-fluid" alt="Image of acupuncture needles in an arm">
</div>
<div class="d-sm-none small-title">
    <h2>Shoulder pain</h2>
</div>
<div id='intro'>
    <p>This is sometimes known as tendinitis or tendinosis.</p>
    <p>A tendon is tough band of fibrous connective tissue that connects muscle to bone and is able to withstand strong tension. If you look at a picture of a muscle
         the tendon is the brilliant white part seen at either end.</p>
    <p>Tendinopathy is a swelling of the tendon which can cause joint pain and stiffness.</p>                        
    <p>Managing a tendinopathy can be quite tricky and people often have poor results. This is because a tendon is does not behave like a muscle and needs to have a very
         specific management and exercise programme. They also take much longer to heal than muscle problems, which is another reason why people can struggle to overcome them.</p>
    <p>Some of the usual conditions that we see at Northam Physiotherapy are:</p>
    <ul>
        <li>Lateral epicondylitis (tennis elbow)</li>
        <li>Medial epicondylitis (golfers’ elbow)</li>
        <li>Patella tendinitis</li>
        <li>Achilles tendinitis</li>
    </ul>
</div>
<div id='treatment'>   
    <h2>Physiotherapy treatment for Tendinopathy</h2>
    <p>Tendinopathy is a condition usually caused through excessive irritation of the tissue, so one of the key things is to identify what is causing the irritation. This may be 
        an activity that you are doing (for example if you have taken up running lately, or have increased the amount of exercise you are doing) but there may also be an alignment
         problem which needs to be addressed.</p>
    <p>Once the irritation has been reduced the tendon should start to feel better, but this is only the 1st part. As mentioned above a healthy tendon is a brilliant white colour, 
        but if it has been irritated for a long time it will break down, become less white and lack strength.</p>
    <p>We will provide you with a structured loading programme to gradually improve the strength of the tendon without overloading it. This can take several months because the 
        tendon will actually re-model itself back to brilliant white.</p>
    <p>Tendon rehabilitation can be complex, however at Northam Physiotherapy we are experts in managing this condition. We want to help you to correct the problem and also make 
        sure that it doesn’t come back</p>
    <button id='bottombackbutton' mdbBtn color="black" outline="true" class="waves-light" mdbWavesEffect (click)="btnBackClick();">
        <mdb-icon fas icon="chevron-left" class="left"></mdb-icon>Conditions
    </button>
</div>

