<div cwdjParallax [config]="{parallaxInitVal: -75, parallaxRatio: -1}" class="view">
    <div class="flex-center rgba-stylish-slight d-none d-md-inline">
        <div class="text-white text-center py-5 px-4 my-5">
              <h2 class="card-title h1-responsive pt-3 mb-5 font-bold"><strong>Treatments</strong></h2>
              <a pageScroll mdbBtn color="white" outline="true" class="waves-light" mdbWavesEffect [fragment]="'exercise'">
                <mdb-icon fas icon="running" class="left"></mdb-icon>Exercise
              </a>
              <a pageScroll mdbBtn color="white" outline="true" class="waves-light" mdbWavesEffect [fragment]="'manual'">
                <mdb-icon fas icon="user" class="left"></mdb-icon>Manual Therapy
              </a>
              <a pageScroll mdbBtn color="white" outline="true" class="waves-light" mdbWavesEffect [fragment]="'acupuncture'">
                <mdb-icon fas icon="map-pin" class="left"></mdb-icon>Acupuncture
              </a>
              <a pageScroll mdbBtn color="white" outline="true" class="waves-light" mdbWavesEffect [fragment]="'electrotherapy'">
                <mdb-icon fas icon="bolt" class="left"></mdb-icon>Electrotherapy
              </a>
        </div>
    </div>
</div>
<div class="d-sm-none">
    <img src="../../assets/images/massagelg.png" class="img-fluid" alt="Image of a person's back being massaged">
</div>
<div class="d-sm-none small-title">
    <h2>Treatments</h2>
</div>
<div id='treatment'>
    <h2>Different types of Physiotherapy treatment</h2>
    <p>There are different types of physiotherapy treatment.  Usually, no one treatment will fix the problem. More often, different types of physiotherapy treatments will all
         help a bit, and you gain your overall improvement with a combination of treatment approaches.</p>
    <p>The main modalities that physios use are:</p>
    <ul>
        <li>Exercise therapy for improved strength or flexibility – these are your home exercises we specify for your problem.</li>
        <li>Hands-on treatment (soft-tissue massage and joint mobilisations).  This is usually called Manual Therapy.</li>
        <li>Pain management treatments like acupuncture.</li>
        <li>Education and advice are also essential so that you can make sure you are doing most of the right things to help yourself day to day.</li>
    </ul>
    <p>Part of the physiotherapy process might also be learning that physiotherapy isn’t the best treatment for you. Some problems need surgery, or different medication, or a 
        different medical professional.  A physiotherapy assessment with us can help find out what might be needed to help you. This information can help your GP decide what to do 
        next to help you.  We are happy to write a report to give you for your GP to help you get to the right help for your problem.</p>
    <p>More detail about the different types of treatment is available below.</p>
</div>
<div>
    <div class="row d-flex">
        <!--<div class="col-md-1 d-flex"></div>-->
        <div class="col-md-3 d-flex" id='exercise'>
            <mdb-card>
                <mdb-card-img src="../../assets/images/julia-ballew-Gh8QHONEHOE-unsplash.jpg" alt="Image of a woman exercising on bleachers"></mdb-card-img>
                <mdb-card-body>
                    <mdb-card-title>
                        <h4>Exercise</h4>
                    </mdb-card-title>
                    <mdb-card-text>The right set of exercises can help most problems.  Whether it is a weak muscle or one that is tight, an exercise targeting that specific problem can make all the difference.
                          Any painful problem usually has a combination of issues with different muscles, and our physiotherapy assessment helps to identify your specific needs.</mdb-card-text>
                    <button mdbBtn color="black" outline="true" class="waves-light" mdbWavesEffect (click)="btnExerciseClick();">
                        More <mdb-icon fas icon="ellipsis-h" class="right"></mdb-icon>
                    </button>      
                </mdb-card-body>
            </mdb-card>
        </div>
        <div class="col-md-3 d-flex" id='manual'>
            <mdb-card>
                <mdb-card-img src="../../assets/images/toa-heftiba-hBLf2nvp-Yc-unsplash.jpg" alt="Image of someone massaging a womens back"></mdb-card-img>
                <mdb-card-body>
                    <mdb-card-title>
                        <h4>Manual Therapy</h4>
                    </mdb-card-title>
                    <mdb-card-text>This is the “hands-on” treatment that can help you get better. Massage and manipulations are versions of manual therapy, as is joint mobilisation. Other versions of manual therapy are the physiotherapist moving a stiff or weak limbs (passive movements), and some stretching techniques.</mdb-card-text>
                    <button mdbBtn color="black" outline="true" class="waves-light" mdbWavesEffect (click)="btnManualClick();">
                        More <mdb-icon fas icon="ellipsis-h" class="right"></mdb-icon>
                    </button>
                </mdb-card-body>
            </mdb-card>
        </div>
        <div class="col-md-3 d-flex" id='acupuncture'>
            <mdb-card>
                <mdb-card-img src="../../assets/images/acupuncture.JPG" alt="image of some acupuncture needles in situ"></mdb-card-img>
                <mdb-card-body>
                    <mdb-card-title>
                        <h4>Acupuncture</h4>
                    </mdb-card-title>
                    <mdb-card-text>Acupuncture is now a mainstream treatment to help reduce pain.  It is used in hospitals and hospices and many GP practices throughout the country. It has been used by physiotherapists for many years to treat pain. We offer acupuncture at Northam Physiotherapy as part of a physiotherapy treatment package.</mdb-card-text>
                    <button mdbBtn color="black" outline="true" class="waves-light" mdbWavesEffect (click)="btnAcuClick();">
                        More <mdb-icon fas icon="ellipsis-h" class="right"></mdb-icon>
                    </button>
                </mdb-card-body>
            </mdb-card>
        </div>
        <div class="col-md-3 d-flex" id='electrotherapy'>
            <mdb-card>
                <mdb-card-img src="../../assets/images/physiotherapy-5624920_1920.jpg" alt="Image of a patient using a TENS machine"></mdb-card-img>
                <mdb-card-body>
                    <mdb-card-title>
                        <h4>Electrotherapy</h4>
                    </mdb-card-title>
                    <mdb-card-text>This is the use of electrical machines and gadgets to help you recover.  This was a mainstream part of physiotherapy in the 1970s and 1980s.  Much of this electrotherapy has been discontinued with modern physiotherapy as research evidence tended to show that it didn’t really help much. </mdb-card-text>
                    <button mdbBtn color="black" outline="true" class="waves-light" mdbWavesEffect (click)="btnElectroClick();">
                        More <mdb-icon fas icon="ellipsis-h" class="right"></mdb-icon>
                    </button>
                </mdb-card-body>
            </mdb-card>
        </div>
    </div>
</div>
