import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-shoulder',
  templateUrl: './shoulder.component.html',
  styleUrls: ['./shoulder.component.scss']
})
export class ShoulderComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  btnBackClick = function () {
    this.router.navigateByUrl('/conditions');
  }

  btnTendinopathyClick = function () {
    this.router.navigateByUrl('/conditions/tendiopathy');
  }
}
