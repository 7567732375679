import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-treatments',
  templateUrl: './treatments.component.html',
  styleUrls: ['./treatments.component.scss']
})
export class TreatmentsComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  btnExerciseClick = function () {
    this.router.navigateByUrl('/treatments/exercise');
  }

  btnManualClick = function () {
    this.router.navigateByUrl('/treatments/manualtherapy');
  }

  btnAcuClick = function () {
    this.router.navigateByUrl('/treatments/acupuncture');
  }

  btnElectroClick = function () {
    this.router.navigateByUrl('/treatments/electrotherapy');
  }

}
