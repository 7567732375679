<div cwdjParallax [config]="{parallaxInitVal: -300, parallaxRatio: -1}" class="view">
    <div class="flex-center rgba-stylish-slight d-none d-md-inline">
        <div class="text-white text-center py-5 px-4 my-5">
              <h2 class="card-title h1-responsive pt-3 mb-5 font-bold"><strong>Chronic pain</strong></h2>
              <a pageScroll mdbBtn color="white" outline="true" class="waves-light" mdbWavesEffect [fragment]="'Causes of chronic pain'">
                <mdb-icon fas icon="bolt" class="left"></mdb-icon>Causes of chronic pain
              </a>
              <a pageScroll mdbBtn color="white" outline="true" class="waves-light" mdbWavesEffect [fragment]="'Can physiotherapy help?'">
                <mdb-icon fas icon="question" class="left"></mdb-icon>Can physiotherapy help?
              </a>
              <button mdbBtn color="white" outline="true" class="waves-light" mdbWavesEffect (click)="btnBackClick();">
                <mdb-icon fas icon="chevron-left" class="left"></mdb-icon>Conditions
              </button>
        </div>
    </div>
</div>
<div class="d-sm-none">
    <img src="../../assets/images/pexels-karolina-grabowska-4506217.jpg" class="img-fluid" alt="Image of acupuncture needles in an arm">
</div>
<div class="d-sm-none small-title">
    <h2>Chronic pain</h2>
</div>
<div id='intro'>
    <p>
        Everyone experiences occasional aches and pains. In fact, sudden pain is an important reaction of the nervous system that helps alert you to possible injury. When an injury occurs, pain signals travel from the injured area up your spinal cord and to your brain.
        Pain will usually become less severe as the injury heals and the “emergency” in the body is over. However, chronic pain is different from typical pain. With chronic pain, your body continues to send pain signals to your brain, even after an injury heals. This can last several weeks to years. Chronic pain can limit your mobility and reduce your flexibility, strength, and endurance. This may make it challenging to get through daily tasks and activities.
        Chronic pain is defined as pain that lasts at least 12 weeks. The pain may feel sharp or dull, causing a burning or aching sensation in the affected areas. It may be steady or intermittent, coming and going without any apparent reason. Chronic pain can occur in nearly any part of your body. The pain can feel different in the various affected areas.</p>
</div>
<div id='causesofchronicpain'>
    <h2>What causes chronic pain?</h2>
    <p>Chronic pain is usually caused by an initial injury, such as a back sprain or pulled muscle. It’s believed that chronic pain develops after nerves become damaged. The nerve 
        damage makes pain more intense and long lasting. In these cases, treating the underlying injury may not resolve the chronic pain.</p>
    <p>In some cases, however, people experience chronic pain without any prior injury. The exact causes of chronic pain without injury aren’t well understood. The pain may sometimes
         result from an underlying health condition.</p>
</div>
<div id='canhelp'>
    <h2>Can Physiotherapy help?</h2>
    <p>Yes, definitely. Chronic pain is complex, and we can help you to understand what is happening. We can help you to move better, relieve your pain, and makes daily tasks and 
        activities like walking, going up stairs or getting in and out of bed easier.</p>
    <p>Physiotherapy for chronic pain can involve manipulation, stretching exercises and pain-relief exercises. We can provide acupuncture and TENs machines to help reduce your pain.
         We can give you advice on the right type of exercise and activity.</p>
    <p>Usually you should begin to feel the benefits after a few sessions.</p>
    <button id='bottombackbutton' mdbBtn color="black" outline="true" class="waves-light" mdbWavesEffect (click)="btnBackClick();">
        <mdb-icon fas icon="chevron-left" class="left"></mdb-icon>Conditions
    </button>
</div>

