import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MDBBootstrapModule } from 'angular-bootstrap-md';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomePageComponent } from './home-page/home-page.component';
import { FourohfourComponent } from './fourohfour/fourohfour.component';
import { TreatmentsComponent } from './treatments/treatments.component';
import { ConditionsComponent } from './conditions/conditions.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { CwdjParallaxModule } from 'cwdj-parallax';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { NgxPageScrollModule} from 'ngx-page-scroll';
import { LinksComponent } from './links/links.component';
import { ExerciseComponent } from './exercise/exercise.component';
import { ManualtherapyComponent } from './manualtherapy/manualtherapy.component';
import { AcupunctureComponent } from './acupuncture/acupuncture.component';
import { ElectrotherapyComponent } from './electrotherapy/electrotherapy.component';
import { TendonComponent } from './tendon/tendon.component';
import { SportsComponent } from './sports/sports.component';
import { ShoulderComponent } from './shoulder/shoulder.component';
import { ChronicpainComponent } from './chronicpain/chronicpain.component';
import { LowbackpainComponent } from './lowbackpain/lowbackpain.component';

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    FourohfourComponent,
    TreatmentsComponent,
    ConditionsComponent,
    AboutusComponent,
    LinksComponent,
    ExerciseComponent,
    ManualtherapyComponent,
    AcupunctureComponent,
    ElectrotherapyComponent,
    TendonComponent,
    SportsComponent,
    ShoulderComponent,
    ChronicpainComponent,
    LowbackpainComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CwdjParallaxModule,
    NgxPageScrollCoreModule.forRoot({duration: 1000}),
    NgxPageScrollModule,
    MDBBootstrapModule.forRoot(),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
