import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { AppComponent } from './app.component';
import { HomePageComponent } from './home-page/home-page.component';
import { FourohfourComponent } from './fourohfour/fourohfour.component';
import { TreatmentsComponent } from './treatments/treatments.component';
import { ConditionsComponent } from './conditions/conditions.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { LinksComponent } from './links/links.component';
import { ExerciseComponent } from './exercise/exercise.component';
import { ManualtherapyComponent } from './manualtherapy/manualtherapy.component';
import { AcupunctureComponent } from './acupuncture/acupuncture.component';
import { ElectrotherapyComponent } from './electrotherapy/electrotherapy.component';
import { ChronicpainComponent } from './chronicpain/chronicpain.component';
import { LowbackpainComponent } from './lowbackpain/lowbackpain.component';
import { ShoulderComponent } from './shoulder/shoulder.component';
import { SportsComponent } from './sports/sports.component';
import { TendonComponent } from './tendon/tendon.component';

const routes: Routes = [
  {path: 'home', component: HomePageComponent},
  {path: 'treatments', component: TreatmentsComponent},
  {path: 'treatments/exercise', component: ExerciseComponent},
  {path: 'treatments/manualtherapy', component: ManualtherapyComponent},
  {path: 'treatments/acupuncture', component: AcupunctureComponent},
  {path: 'treatments/electrotherapy', component: ElectrotherapyComponent},
  {path: 'conditions', component: ConditionsComponent},
  {path: 'conditions/chronicpain', component: ChronicpainComponent},
  {path: 'conditions/lowbackpain', component: LowbackpainComponent},
  {path: 'conditions/shoulderpain', component: ShoulderComponent},
  {path: 'conditions/sportsinjury', component: SportsComponent},
  {path: 'conditions/tendinopathy', component: TendonComponent},
  {path: 'aboutus', component: AboutusComponent},
  {path: 'links', component: LinksComponent},
  {path: '', redirectTo: 'home', pathMatch: 'full'},
  {path: '**', component: FourohfourComponent},
];

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  scrollOffset: [0,64],
}

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
