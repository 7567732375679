<div cwdjParallax [config]="{parallaxInitVal: -75, parallaxRatio: -1}" class="view">
    <div class="flex-center rgba-stylish-slight d-none d-md-inline">
        <div class="text-white text-center py-5 px-4 my-5">
              <h2 class="card-title h1-responsive pt-3 mb-5 font-bold"><strong>Useful links</strong></h2>
        </div>
    </div>
</div>
<div class="d-sm-none">
    <img src="../../assets/images/massagelg.png" class="img-fluid" alt="Image of a person's back being massaged">
</div>
<div class="d-sm-none small-title">
    <h2>Useful links</h2>
</div>
<div>
    <div class="row d-flex">
        <!--<div class="col-md-1 d-flex"></div>-->
        <div class="col-md-4 d-flex" id='CSP'>
            <mdb-card>
                <mdb-card-img id="csp-logo" src="../../assets/images/csp.png" alt="Logo of Chartered Society of Physiotherapy"></mdb-card-img>
                <mdb-card-body>
                    <mdb-card-title>
                        <h4>The Chartered Society of Physiotherapy</h4>
                    </mdb-card-title>
                    <mdb-card-text>The Chartered Society of Physiotherapy (CSP) is the professional, educational and trade union body for the UK's 59,000 chartered physiotherapists, physiotherapy students and support workers.</mdb-card-text>
                    <a mdbBtn color="black" outline="true" class="waves-light" mdbWavesEffect href="https://www.csp.org.uk">
                        Website <mdb-icon fas icon="globe" class="right"></mdb-icon>
                    </a>
                </mdb-card-body>
            </mdb-card>
        </div>
        <div class="col-md-4 d-flex" id='physiofirst'>
            <mdb-card>
                <mdb-card-img src="../../assets/images/physiofirst.png" alt="PhysioFirst logo"></mdb-card-img>
                <mdb-card-body>
                    <mdb-card-title>
                        <h4>PhysioFirst</h4>
                    </mdb-card-title>
                    <mdb-card-text>Physio First was originally formed as the Organisation of Chartered Physiotherapists in Private Practice (OCPPP), later becoming Physio First. The continuous developments in the NHS and the private healthcare market have always presented a number of challenges for our members and, as a result, Physio First has grown and adapted to meet the needs of our members in today’s fast changing and often challenging healthcare marketplace.

                        We benefit from professional management and support and pride ourselves on being an extremely functional organisation that is run by its members.</mdb-card-text>
                    <a mdbBtn color="black" outline="true" class="waves-light" mdbWavesEffect href="https://physiofirst.org.uk">
                        Website <mdb-icon fas icon="globe" class="right"></mdb-icon>
                    </a>
                </mdb-card-body>
            </mdb-card>
        </div>
        <div class="col-md-4 d-flex" id='hcpc'>
            <mdb-card>
                <mdb-card-img src="../../assets/images/hcpc.png" alt="Health and Care Professions Logo"></mdb-card-img>
                <mdb-card-body>
                    <mdb-card-title>
                        <h4>Health and Care Professions Council</h4>
                    </mdb-card-title>
                    <mdb-card-text class="add-space">We are a regulator of health and care professions in the UK. Our role is to protect the public. By law, people must be registered with us to work in the UK in any of the protected professions (list on website).</mdb-card-text>
                    <a mdbBtn color="black" outline="true" class="waves-light" mdbWavesEffect href="https://hcpc-uk.org">
                        Website <mdb-icon fas icon="globe" class="right"></mdb-icon>
                    </a>
                </mdb-card-body>
            </mdb-card>
        </div>
    </div>
</div>
